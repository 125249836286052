import { useUserData } from '@/context/FirestoreContext';
import { TranslatedText } from '../ui/atoms/TranslatedText';
import { Button } from '../ui/molecules/Button';
import {
  useTonConnectUI,
  useTonWallet,
  useTonConnectModal,
} from '@tonconnect/ui-react';
import {
  ADD_TON_WALLET_ENDPOINT,
  REMOVE_TON_WALLET_ENDPOINT,
} from '@/config/endpoints';
import useAPI from '@/hooks/useAPI';
import { useEffect, useState } from 'react';

export const ConnectTonWallet = () => {
  const { userData } = useUserData();
  const [isSaving, setIsSaving] = useState(false);
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  const { open: openConnectTonWalletModal } = useTonConnectModal();

  const { apiPut } = useAPI();

  const addWallet = async (walletAddress: string) => {
    try {
      setIsSaving(true);
      await apiPut(ADD_TON_WALLET_ENDPOINT, {
        walletAddress,
      });
    } catch (error: any) {
      console.error(error);
      alert({ title: error?.message ?? 'Error' });
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (wallet?.account.address) {
      addWallet(wallet?.account.address);
    }
  }, [wallet]);

  return userData?.tonWallet ? (
    <>
      <Button
        mb={2}
        w="full"
        h={'46px'}
        colorId={`Black`}
        isDisabled={isSaving}
        whiteSpace={'pre-wrap'}
        onClick={async () => {
          try {
            setIsSaving(true);
            await apiPut(REMOVE_TON_WALLET_ENDPOINT, {}, true);
            await tonConnectUI.disconnect();
          } catch (error: any) {
            console.error(error);
            alert({ title: error?.message ?? 'Error' });
          } finally {
            setIsSaving(false);
          }
        }}
      >
        <TranslatedText
          translationKey={`disconnect`}
          defaultMessage={`disconnect`}
        />
      </Button>
    </>
  ) : (
    <Button
      mb={2}
      w="full"
      h={'46px'}
      colorId={`Black`}
      isDisabled={isSaving}
      whiteSpace={'pre-wrap'}
      onClick={async () => {
        // open connect ton wallet drawer
        openConnectTonWalletModal();
      }}
    >
      <TranslatedText
        translationKey={`connectWallet`}
        defaultMessage={`connect wallet`}
      />
    </Button>
  );
};
